import React, { useEffect, useState } from "react";
import iconSquareAnswer from "../../img/squareAnswerIcon.svg";
import iconEditBnt from "../../img/OrganizationsLisеIconEditButton.svg";
import iconDeleteBtn from "../../img/AddNewGroupIconBasket.svg";
import useShowVariantsAnswerInCreatedQuestion
    from "../../utils/CustomHooks/useShowVariantsAnswerInCreatedQuestion/useShowVariantsAnswerInCreatedQuestion";

const AddNewVoteCreatedQuestion = (props) => {

    const {
        constants,
        question,
        deleteQuestion,
        editQuestion,
        index
    } = props;

    const [type, setType] = useState('');
    const [isShowListAnswerGrid] = useShowVariantsAnswerInCreatedQuestion(question);

    useEffect(() => {
        let isMounted = true;
        if (question.template === 'ynq') {
            isMounted && setType(constants.ADD_NEW_VOTE.ADD_NEW_VOTE_QUESTION_YNQ);
        } else if (question.template === 'none') {
            isMounted && setType(constants.ADD_NEW_VOTE.ADD_NEW_VOTE_QUESTION_NONE);
        } else if (question.template === 'position_single') {
            isMounted && setType(constants.ADD_NEW_VOTE.ADD_NEW_VOTE_QUESTION_POSITION_SINGLE);
        } else if (question.template === 'grid') {
            isMounted && setType(constants.ADD_NEW_VOTE.ADD_NEW_VOTE_QUESTION_GRID);
        } else if (question.template === 'radio_grid') {
            isMounted && setType(constants.ADD_NEW_VOTE.ADD_NEW_VOTE_QUESTION_RADIO_GRID);
        } else if (question.template === 'position_multiple') {
            isMounted && setType(constants.ADD_NEW_VOTE.ADD_NEW_VOTE_QUESTION_POSITION_MULTIPLE);
        } else if (question.template === 'same_positions') {
            isMounted && setType(constants.ADD_NEW_VOTE.ADD_NEW_VOTE_QUESTION_SAME_POSITIONS);
        }
        return () => {
            isMounted = false;
        }
    }, [question.template])

    console.log(question)

    return (
        <div className="add-new-vote-created-question">
            <div className="add-new-vote-created-question__question-block">
                <div className="add-new-vote-created-question__name-type-questions">
                    <h3 className="add-new-vote-created-question__name-question">{index + 1}.{question.title}</h3>
                    <p className="add-new-vote-created-question__type-question">{type}</p>
                </div>
                {isShowListAnswerGrid ? (
                    <div className="add-new-vote-created-question__list-answer-grid">
                        <div className="add-new-vote-created-question__list-answer-grid-row">
                            <h3 className="add-new-vote-created-question__list-answer-title">{constants.ADD_NEW_VOTE.QUESTION_TYPE_GRID_ROWS}</h3>
                            {question?.options?.rows?.map((item, i) => {
                                return (
                                    <div key={i} className="add-new-vote-created-question__list-answer-row">
                                        <img className="add-new-vote-created-question__list-answer-square-icon" src={iconSquareAnswer} alt={constants.GENERAL.ALT_ICON}/>
                                        <p className="add-new-vote-created-question__list-answer-item">{item?.value}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="add-new-vote-created-question__list-answer-grid-columns">
                            <h3 className="add-new-vote-created-question__list-answer-title">{constants.ADD_NEW_VOTE.QUESTION_TYPE_GRID_COLUMNS}</h3>
                            {question?.options?.columns?.map((item, i) => {
                                return (
                                    <div key={i} className="add-new-vote-created-question__list-answer-row">
                                        <img className="add-new-vote-created-question__list-answer-square-icon" src={iconSquareAnswer} alt={constants.GENERAL.ALT_ICON}/>
                                        <p className="add-new-vote-created-question__list-answer-item">{item?.value}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="add-new-vote-created-question__list-answer">
                        <h3 className="add-new-vote-created-question__list-answer-title">{constants.ADD_NEW_VOTE.QUESTION_TYPE_VARIANTS_ANSWER}</h3>
                        {question?.options?.rows?.map((item, i) => {
                            return (
                                <div key={i} className="add-new-vote-created-question__list-answer-row">
                                    <img className="add-new-vote-created-question__list-answer-square-icon" src={iconSquareAnswer} alt={constants.GENERAL.ALT_ICON}/>
                                    <p className="add-new-vote-created-question__list-answer-item">{item?.value}</p>
                                </div>
                            )
                        })}
                    </div>
                )}
                <div className="add-new-vote-created-question__buttons-block">
                    <div className="add-new-vote-created-question__edit-button" onClick={() => editQuestion(question, index)}>
                        <img className="add-new-vote-created-question__icon-edit" src={iconEditBnt} alt={constants.GENERAL.ALT_ICON} />
                        <p>{constants.ADD_NEW_VOTE.CREATED_QUESTION_EDIT_BTN}</p>
                    </div>
                    <div className="add-new-vote-created-question__delete-button" onClick={() => deleteQuestion(question)}>
                        <img className="add-new-vote-created-question__icon-delete" src={iconDeleteBtn} alt={constants.GENERAL.ALT_ICON} />
                        <p>{constants.ADD_NEW_VOTE.CREATED_QUESTION_DELETE_BTN}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddNewVoteCreatedQuestion;
