import React, {useEffect, useRef, useState} from "react";
import iconTime from "../../img/VotesPageActiveVotes_time_icon.svg";
import iconDate from "../../img/DetailsVoteStatisitcsVoteIconDate.svg";
import iconGreenCircle from "../../img/DetailsVoteStaticticsIconGreenCircle.svg";
import iconYellowCircle from "../../img/DetailsVoteStaticticsIconYellowCircle.svg";
import iconGreenDash from "../../img/DetailsVoteStaticticsIconGreenDash.svg";
import iconYellowDash from "../../img/DetailsVoteStaticticsIconYellowDash.svg";
import iconBlueDashGraph from "../../img/DetailsVoteStaticticsIconBlueDashGraph.svg";
import iconGreenDashGraph from "../../img/DetailsVoteStaticticsIconGreenDashGraph.svg";
import iconQuorumReached from "../../img/AddNewOrgSuccessIcon.svg";
import iconNotQuorumReached from "../../img/DetailsVoteVotingIconNoVote.svg";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import PaginationBlock from "../PaginationBlock/PaginationBlock";
import useSectorCircleReg from "../../utils/CustomHooks/useSectorCircleReg/useSectorCircleReg";
import useSectorCircleVote from "../../utils/CustomHooks/useSectorCircleVote/useSectorCircleVote";
import {setConditionQuorum} from "../../utils/SetConditionQuorum/setConditionQuorum";

const DetailsVoteStatisticsVote = (props) => {

    const {
        constants,
        formatDate,
        formatTime,
        currentEventData,
        statisticsVoteTable
    } = props;

    const [activeDynamicsVisit, setActiveDynamicsVisit] = useState(false);
    const [activeGraphVisit, setActiveGraphVisit] = useState(true);
    const [activeTableVisit, setActiveTableVisit] = useState(false);
    const [sectorCircleReg, setSectorCircleReg] = useSectorCircleReg(currentEventData?.statistics?.registeredUsersPercentage);
    const [sectorCircleVote, setSectorCircleVote] = useSectorCircleVote(currentEventData?.statistics?.votedUsersPercentage);
    const progressBarRegRef = useRef(null);
    const progressBarVoteRef = useRef(null);
    const isChartSumPercentage = statisticsVoteTable?.length !== 0 ? statisticsVoteTable?.statistics?.map(el => el?.cumSumPercentage) : [];
    const isChartTurnoutPercentage = statisticsVoteTable?.length !== 0 ? statisticsVoteTable?.statistics?.map(el => el?.turnoutPercentage) : [];

    const onChangeGraphVisit = () => {
        setActiveGraphVisit(true);
        setActiveTableVisit(false);
    }

    const onChangeTableVisit = () => {
        setActiveTableVisit(true);
        setActiveGraphVisit(false);
    }

    const calculateNotRegistration = (value) => {
        return 100 - value
    }

    const calculateNotVote = (value) => {
        return 100 - value
    }

    useEffect(() => {
        if (currentEventData?.statistics?.registeredUsersPercentage !== 0 && currentEventData?.length !== undefined) {
            progressBarRegRef.current.style.width = `${currentEventData?.statistics?.registeredUsersPercentage}%`;
        } else {
            return progressBarRegRef.current.style.width = '0%';
        }

        if (currentEventData?.statistics?.votedUsersPercentage !== 0 && currentEventData?.length !== undefined) {
            progressBarVoteRef.current.style.width = `${currentEventData?.statistics?.votedUsersPercentage}%`;
        } else {
            return progressBarVoteRef.current.style.width = '0%';
        }
    },[currentEventData])

    function drawCircles(radius, sectorCircle, colorsCircle) {
        let circleFull = 2 * Math.PI * radius;
        let gapBetweenCircle = sectorCircle === 1 ? 0 : 1;
        let circleFill = circleFull * sectorCircle;
        let circleEmpty = circleFull - circleFill;
        let circleOffset = circleFull / 4;

        return (
            <>
                {sectorCircle && typeof (sectorCircle) === 'number' && (
                    <svg className={'diagramm-circle'} viewBox={'0 0 50 50'}>
                        <circle className={'circle__style'} r={radius} cx={'50%'} cy={'50%'} stroke={colorsCircle[0]} strokeDasharray={(circleFill - gapBetweenCircle) + ' ' + circleEmpty} strokeDashoffset={circleOffset} />
                        <circle className={'circle__style'} r={radius} cx={'50%'} cy={'50%'} stroke={colorsCircle[1]} strokeDasharray={(circleEmpty - gapBetweenCircle) + ' ' + circleFill} strokeDashoffset={circleOffset - circleFill + gapBetweenCircle / 2} />
                    </svg>
                )}
            </>
        )}

    return (
        <div className="details-vote-statistics-vote__container">
            <h3 className="details-vote-statistics-vote__title-mobile">{constants.DETAILS_VOTE.STATISTICS_TITLE_MOBILE}</h3>
            <div className="details-vote-statistics-vote-title-block">
                <h3 className="details-vote-statistics-vote__title-and">{constants.DETAILS_VOTE.STATISTIC_TITLE_MOVE}</h3>
                <h3 className="details-vote-statistics-vote__title">{constants.DETAILS_VOTE.STATISTIC_TITLE_REGISTRATION}</h3>
                {currentEventData?.statistics?.numVotedUsers !== 0 && <div className="details-vote-statistics-vote__title-voted-mobile">
                    <h3 className="details-vote-statistics-vote__title-and">{constants.DETAILS_VOTE.STATISTIC_TITLE_AND}</h3>
                    <h3 className="details-vote-statistics-vote__title">{constants.DETAILS_VOTE.STATISTIC_TITLE_VOTED}</h3>
                </div>}
            </div>
            <div className="details-vote-statistics-vote__last-update">
                <p className="details-vote-statistics-vote__last-update-label">{constants.DETAILS_VOTE.STATISTIC_LATEST_UPDATE}</p>
                <img src={iconDate} alt={constants.GENERAL.ALT_ICON}/>
                <p className="details-vote-statistics-vote__last-update-icon-label">{currentEventData?.statistics?.updatedTime !== undefined ? formatDate(currentEventData?.statistics?.updatedTime) : ''}</p>
                <img src={iconTime} alt={constants.GENERAL.ALT_ICON}/>
                <p className="details-vote-statistics-vote__last-update-icon-label">{currentEventData?.statistics?.updatedTime !== undefined ? formatTime(currentEventData?.statistics?.updatedTime) : ''}</p>
            </div>
            <div className="details-vote-general-info__results-diagramm-block">
                <div className="details-vote-statistics-vote__result-voted-registred-block">
                    <div className="details-vote-statistics-vote__result-registred">
                        <div className="details-vote-statistics-vote__result-registred-items">
                            <img className="details-vote-statistics-vote__result-registred-icon" src={iconGreenCircle} alt={constants.GENERAL.ALT_ICON}/>
                            <p className="details-vote-statistics-vote__result-registred-label">{constants.DETAILS_VOTE.STATISTIC_REGISTRED_MEMBERS} ({currentEventData?.statistics?.registeredUsersPercentage}%)</p>
                        </div>
                        <div className="details-vote-statistics-vote__result-registred-items">
                            <img className="details-vote-statistics-vote__result-registred-icon" src={iconYellowCircle} alt={constants.GENERAL.ALT_ICON}/>
                            <p className="details-vote-statistics-vote__result-registred-label">{constants.DETAILS_VOTE.STATISTIC_NOT_REGISTRED_MEMBERS} ({calculateNotRegistration(currentEventData?.statistics?.registeredUsersPercentage)}%)</p>
                        </div>
                    </div>
                </div>
                <div className="details-vote-statistics-vote__diagramm-block">
                    {drawCircles(22, sectorCircleReg, ['#4ED4A9', '#F9C521'])}
                    <div className="details-vote-statistics-vote__diagramm-block-numbers-reg-voting">
                        <p className="details-vote-statistics-vote__diagramm-num-reg">{currentEventData?.statistics?.numRegisteredUsers}</p>
                        <p className="details-vote-statistics-vote__diagramm-label-reg">{constants.DETAILS_VOTE.STATISTIC_REG_GENERAL_MEMBERS}</p>
                    </div>
                </div>
                <div className="details-vote-statistics-vote__status-event-quorum-condition">
                    <div className="details-vote-statistics-vote__quorum">
                        <p className="details-vote-statistics-vote__quorum-label">{constants.DETAILS_VOTE.STATISTIC_QUORUM_CONDITION}</p>
                        <p className="details-vote-statistics-vote__quorum-note">{setConditionQuorum(currentEventData?.quorum, constants)}</p>
                    </div>
                    <div className="details-vote-statistics-vote__status-event">
                        <p className="details-vote-statistics-vote__status-event-label">{constants.DETAILS_VOTE.STATISTIC_STATUS_EVENT}</p>
                        {currentEventData?.statistics?.quorumReached === true ?
                            <p className="details-vote-statistics-vote__status-event-icon">
                                <img src={iconQuorumReached} alt={constants.GENERAL.ALT_ICON}/>{constants.DETAILS_VOTE.STATISTIC_QUORUM_SUCCESS}
                            </p> :
                            <p className="details-vote-statistics-vote__status-event-icon-not-reached">
                                <img src={iconNotQuorumReached} alt={constants.GENERAL.ALT_ICON}/>{constants.DETAILS_VOTE.STATISTIC_QUORUM_NOT_SUCCESS}
                            </p>}
                    </div>
                    <div className="details-vote-statistics-vote__progress-visit-vote">
                        <p className="details-vote-statistics-vote__progress-visit-vote-label">{constants.DETAILS_VOTE.STATISTIC_ELECTRONIC_VISIT} ({currentEventData?.statistics?.registeredUsersPercentage}%)</p>
                        <div className="details-vote-statistics-vote__progress">
                            <div ref={progressBarRegRef} className="details-vote-statistics-vote__progress-bar"></div>
                        </div>
                    </div>
                </div>
            </div>
            {currentEventData?.statistics?.numVotedUsers !== 0 &&
                <div className="details-vote-general-info__results-diagramm-block">
                    <div className="details-vote-statistics-vote__result-voted-registred-block">
                        <div className="details-vote-statistics-vote-title-block-voted">
                            <h3 className="details-vote-statistics-vote__title-and">{constants.DETAILS_VOTE.STATISTIC_TITLE_MOVE}</h3>
                            <h3 className="details-vote-statistics-vote__title">{constants.DETAILS_VOTE.STATISTIC_TITLE_VOTED}</h3>
                        </div>
                        <div className="details-vote-statistics-vote__result-voted">
                            <div className="details-vote-statistics-vote__result-voted-items">
                                <img className="details-vote-statistics-vote__result-voted-icon" src={iconGreenDash} alt={constants.GENERAL.ALT_ICON}/>
                                <p className="details-vote-statistics-vote__result-voted-label">{constants.DETAILS_VOTE.STATISTIC_VOTED} ({currentEventData?.statistics?.votedUsersPercentage}%) </p>
                            </div>
                            <div className="details-vote-statistics-vote__result-voted-items">
                                <img className="details-vote-statistics-vote__result-voted-icon" src={iconYellowDash} alt={constants.GENERAL.ALT_ICON}/>
                                <p className="details-vote-statistics-vote__result-voted-label">{constants.DETAILS_VOTE.STATISTIC_NOT_VOTED} ({calculateNotVote(currentEventData?.statistics?.votedUsersPercentage)}%)</p>
                            </div>
                        </div>
                    </div>
                    <div className="details-vote-statistics-vote__diagramm-block">
                        {drawCircles(22, sectorCircleVote, ['#4ED4A9', '#F9C521'])}
                        <div className="details-vote-statistics-vote__diagramm-block-numbers-voting">
                            <p className="details-vote-statistics-vote__diagramm-num-reg">{currentEventData?.statistics?.numVotedUsers}</p>
                            <p className="details-vote-statistics-vote__diagramm-label-reg">{constants.DETAILS_VOTE.STATISTIC_VOTED_GENERAL_MEMBERS}</p>
                        </div>
                    </div>
                    <div className="details-vote-statistics-vote__status-event-quorum-condition">
                        <div className="details-vote-statistics-vote__quorum">
                            <p className="details-vote-statistics-vote__quorum-label">{constants.DETAILS_VOTE.STATISTIC_QUORUM_CONDITION}</p>
                            <p className="details-vote-statistics-vote__quorum-note">{setConditionQuorum(currentEventData?.quorum, constants)}</p>
                        </div>
                        <div className="details-vote-statistics-vote__status-event">
                            <p className="details-vote-statistics-vote__status-event-label">{constants.DETAILS_VOTE.STATISTIC_STATUS_EVENT}</p>
                            {currentEventData?.statistics?.quorumReached === true ?
                                <p className="details-vote-statistics-vote__status-event-icon">
                                    <img src={iconQuorumReached} alt={constants.GENERAL.ALT_ICON}/>{constants.DETAILS_VOTE.STATISTIC_QUORUM_SUCCESS}
                                </p> :
                                <p className="details-vote-statistics-vote__status-event-icon-not-reached">
                                    <img src={iconNotQuorumReached} alt={constants.GENERAL.ALT_ICON}/>{constants.DETAILS_VOTE.STATISTIC_QUORUM_NOT_SUCCESS}
                                </p>}
                        </div>
                        <div className="details-vote-statistics-vote__progress-visit-vote">
                            <p className="details-vote-statistics-vote__progress-visit-vote-label">{constants.DETAILS_VOTE.STATISTIC_ELECTRONIC_VISIT} ({currentEventData?.statistics?.votedUsersPercentage}%)</p>
                            <div className="details-vote-statistics-vote__progress">
                                <div ref={progressBarVoteRef} className="details-vote-statistics-vote__progress-bar"></div>
                            </div>
                        </div>
                    </div>
                </div>}
            {statisticsVoteTable?.length !== 0 ? (
                <>
            <p onClick={() => setActiveDynamicsVisit(true)} className={activeDynamicsVisit ? "details-vote-statistics-vote__show-more-btn hidden" : "details-vote-statistics-vote__show-more-btn" }>
                {constants.DETAILS_VOTE.STATISTIC_SHOW_MORE_BTN}
            </p>
                <div className={activeDynamicsVisit ? "details-vote-statistics-vote__dynamics-visit" : "details-vote-statistics-vote__dynamics-visit hidden"}>
                    <p className="details-vote-statistics-vote__dynamics-visit-title">{constants.DETAILS_VOTE.STATISTIC_DYNAMIC_VISIT}</p>
                    <div className="details-vote-statistics-vote__dynamics-visit-change-graph-table">
                        <div onClick={() => onChangeGraphVisit()} className={activeGraphVisit ? "details-vote-statistics-vote__change-graph-btn active" : "details-vote-statistics-vote__change-graph-btn"}>
                        </div>
                        <div onClick={() => onChangeTableVisit()} className={activeTableVisit ? "details-vote-statistics-vote__change-table-btn active" : "details-vote-statistics-vote__change-table-btn"}>
                        </div>
                    </div>
                    {activeGraphVisit && (
                        <div className="details-vote-statistics-vote__dynamics-visit-graph">
                            <div className="details-vote-statistics-vote__visit-graph-explanations">
                                <div className="details-vote-statistics-vote__visit-graph-explanations-general-visit">
                                    <img src={iconBlueDashGraph} alt={constants.GENERAL.ALT_ICON}/>
                                    <p>{constants.DETAILS_VOTE.STATISTIC_GENERAL_VISIT}</p>
                                </div>
                                <div className="details-vote-statistics-vote__visit-graph-explanations-voting-visit">
                                    <img src={iconGreenDashGraph} alt={constants.GENERAL.ALT_ICON}/>
                                    <p>{constants.DETAILS_VOTE.STATISTIC_VISIT_VOTING}</p>
                                </div>
                            </div>
                            <div className="details-vote-statistics-vote__visit-graph">
                                <div className="details-vote-statistics-vote__hidden-block"></div>
                                <Chart width={'914px'} height={'453px'} type={'line'} data={{
                                    labels: statisticsVoteTable?.statistics?.length === 1 ? [formatTime(statisticsVoteTable?.statistics[0]?.startTime), formatTime(statisticsVoteTable?.statistics[0]?.endTime)] : formatTime(statisticsVoteTable?.statistics[0]?.startTime),
                                    datasets: [
                                        {
                                            label: 'Общая явка',
                                            data: statisticsVoteTable?.statistics?.length === 1 ? [0, isChartSumPercentage] : isChartSumPercentage,
                                            fill: false,
                                            borderColor: '#0084FE',
                                            tension: 0.5,
                                            pointRadius: 0.8,
                                            pointBackgroundColor: '#0084FE',
                                            borderWidth: 2,
                                            backgroundColor: '#0084FE'
                                        },
                                        {
                                            label: 'Явка голосующих',
                                            data: statisticsVoteTable?.statistics?.length === 1 ? [0, isChartSumPercentage] : isChartTurnoutPercentage,
                                            fill: false,
                                            borderColor: '#4ED4A9',
                                            tension: 0.5,
                                            pointRadius: 0.8,
                                            pointBackgroundColor: '#4ED4A9',
                                            borderWidth: 2,
                                            backgroundColor: '#4ED4A9'
                                        }
                                    ]
                                }}/>
                            </div>
                        </div>
                    )}
                    {activeTableVisit && (
                        <div className="details-vote-statistics-vote__dynamics-visit-table">
                            {/*<PaginationBlock/>*/}
                            <div className="details-vote-statistics-vote__visit-table">
                                <div className="details-vote-statistics-vote__visit-table-header">
                                    <p className="details-vote-statistics-vote__visit-table-header-time">{constants.DETAILS_VOTE.STATISTIC_TABLE_TIME}</p>
                                    <p className="details-vote-statistics-vote__visit-table-header-visit-time">{constants.DETAILS_VOTE.STATISTIC_TABLE_VISIT_TIME}</p>
                                    <p className="details-vote-statistics-vote__visit-table-header-general-visit">{constants.DETAILS_VOTE.STATISTIC_GENERAL_VISIT}</p>
                                </div>
                                {statisticsVoteTable?.statistics?.length !== 0 ? (
                                    <>
                                        {statisticsVoteTable?.statistics?.map((el, i) => (
                                            <div key={i} className="details-vote-statistics-vote__visit-table-row">
                                                <p className="details-vote-statistics-vote__visit-table-row-colimn-time">{formatTime(el?.startTime)} - {formatTime(el?.endTime)}</p>
                                                <p className="details-vote-statistics-vote__visit-table-row-colimn-visit-time">{el?.turnoutPercentage}%</p>
                                                <p className="details-vote-statistics-vote__visit-table-row-colimn-general-visit">{el?.cumSumPercentage}%</p>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div className="details-vote-statistics-vote__empty-table-date">
                                        <p>{constants.DETAILS_VOTE.STATISTIC_EMPTY_DATA_VISIT_VOTERS_TABLE}</p>
                                    </div>
                                )}

                            </div>
                            {/*<PaginationBlock/>*/}
                        </div>
                    )}
                </div>
            </>
            ) : (
                <p className='details-vote-statistics-vote__empty-data-visit-voters'>
                    {constants.DETAILS_VOTE.STATISTIC_EMPTY_DATA_VISIT_VOTERS}
                </p>
            )}
            <p onClick={() => setActiveDynamicsVisit(false)} className={activeDynamicsVisit ? "details-vote-statistics-vote__roll-up-btn" : "details-vote-statistics-vote__roll-up-btn hidden"}>
                {constants.DETAILS_VOTE.STATISTIC_HIDE_BTN}
            </p>
        </div>
    )
}
export default DetailsVoteStatisticsVote;