import React, {useEffect, useState} from "react";
import iconEye from "../../img/ListUsersIconEye.svg";
import GeneralTitleAllPages from "../GeneralTitleAllPages/GeneralTitleAllPages";
import PaginationBlock from "../PaginationBlock/PaginationBlock";
import iconUpDownSort from "../../img/upDownSortIcon.svg";
import orgSearchIconMobile from "../../img/PaginationSearchIcon.svg";
import {Validation} from "../../utils/Validation/Validation";
import {onSetCountPageForSearch} from "../../utils/OnSetCountPageForSearch/OnSetCountPageForSearch";

const ListUsers = (props) => {

    const {
        constants,
        allUsers,
        handleSelectUser
    } = props;

    const [isUsersTable, setUsersTable] = useState([]);
    const usersSearch = Validation();
    const [usersForRender, setUsersForRender] = useState([]);
    const [isSortEmailToggle, setSortEmailToggle] = useState(false);
    const [isSortLastNameToggle, setSortLastNameToggle] = useState(false);
    const [usersSearchInput, setUsersSearchInput] = useState('');
    const [showResultsFrom, setShowResultsFrom] = useState(0);
    const [resultsShow, setResultsShow] = useState(5);
    const [result, setResult] = useState(5);
    const [pageCount, setPageCount] = useState(1);
    const [selectedResultsShow, setSelectedResultsShow] = useState(5);

    useEffect(() => {
        let isMounted = true;
        const users = [];
        allUsers.forEach((el) => {
            const newUser = {
                id: el?.email,
                email: el?.email,
                first_name: el?.first_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}` : el?.first_name,
                last_name: el?.last_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}` : el?.last_name,
                second_name: el?.second_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}` : el?.second_name,
            }
            isMounted && users.push(newUser);
        })
        isMounted && setUsersTable(users)
        return () => {
            isMounted = false;
        }
    },[allUsers])


    const onChangeTypeSortEmail = (typeSort) => {
        if(typeSort) {
            usersForRender.sort(function (a, b){
                const emailA = b.email.toLowerCase()
                const emailB = a.email.toLowerCase()
                if (emailA < emailB)
                    return -1
                if (emailA > emailB)
                    return 1
                return 0
            })
            setSortEmailToggle(false);
        } else {
            usersForRender.sort(function (a, b){
                const emailA = a.email.toLowerCase()
                const emailB = b.email.toLowerCase()
                if (emailA < emailB)
                    return -1
                if (emailA > emailB)
                    return 1
                return 0
            })
            setSortEmailToggle(true);
        }
    }

    const onChangeTypeSortUsername = (typeSort) => {
        if(typeSort) {
            usersForRender.sort(function (a, b){
                const emailA = b.last_name.toLowerCase()
                const emailB = a.last_name.toLowerCase()
                if (emailA < emailB)
                    return -1
                if (emailA > emailB)
                    return 1
                return 0
            })
            setSortLastNameToggle(false);
        } else {
            usersForRender.sort(function (a, b){
                const emailA = a.last_name.toLowerCase()
                const emailB = b.last_name.toLowerCase()
                if (emailA < emailB)
                    return -1
                if (emailA > emailB)
                    return 1
                return 0
            })
            setSortLastNameToggle(true);
        }
    }


    function handleShowResultsFrom(value) {
        setShowResultsFrom(value);
    }

    function handleResultsShow(value) {
        setResultsShow(value);
    }

    function showPrevResults() {
        if (resultsShow <= result) {
            return resultsShow
        } else {
            setShowResultsFrom(showResultsFrom - result);
            handleShowResultsFrom(showResultsFrom - result);
            setResultsShow(resultsShow - result);
            handleResultsShow(resultsShow - result);
            setPageCount(pageCount - 1);
        }
    }

    function showNextResults() {
        if (resultsShow >= usersForRender.length) {
            return resultsShow
        } else {
            setShowResultsFrom(resultsShow);
            handleShowResultsFrom(resultsShow);
            setResultsShow(result + resultsShow);
            handleResultsShow(result + resultsShow);
            setPageCount(pageCount + 1);
        }
    }

    function onChoiceClick(value) {
        setResultsShow(value);
        handleResultsShow(value);
        setResult(value);
        setSelectedResultsShow(value);
        setShowResultsFrom(0);
        handleShowResultsFrom(0);
        setPageCount(1);
    }

    function searchInput(value) {
        setUsersSearchInput(value);
    }

    useEffect(() => {
        let isMounted = true;
            if (usersSearchInput === '') {
                isMounted && setUsersForRender(isUsersTable.sort(function (a, b){
                    const emailA = b.email.toLowerCase()
                    const emailB = a.email.toLowerCase()
                    if (emailA < emailB)
                        return -1
                    if (emailA > emailB)
                        return 1
                    return 0
                }));
                isMounted && onChoiceClick(5);
            } else {
                const dataForRender = [];
                isUsersTable.forEach((user) => {
                    if (user.email.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                        isMounted && dataForRender.push(user);
                    } else if (user.last_name.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                        isMounted && dataForRender.push(user);
                    } else if (user.first_name.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                        isMounted && dataForRender.push(user);
                    } else if (user.second_name.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                        isMounted && dataForRender.push(user);
                    }
                })
                isMounted && onSetCountPageForSearch(dataForRender.length, onChoiceClick);
                isMounted && setUsersForRender(dataForRender);
            }
            return () => {
                isMounted = false;
            }
        }, [usersSearchInput, isUsersTable]);

    return (
        <div className="container__list-users _container">
            <GeneralTitleAllPages
                titleName={constants.GENERAL_TITLE.GENERAL_TITLE_TITLENAME_LIST_USERS}
                firstLetter={constants.GENERAL_TITLE.GENERAL_TITLE_FIRTSLETTER}
                secondLetter={constants.GENERAL_TITLE.GENERAL_TITLE_SECONDLETTER_LIST_USERS}
            />
            <div className="list-users__sorting-pagination">
                <div className="list-users__pagination">
                    <PaginationBlock
                        sortList={usersForRender}
                        search={usersSearch}
                        searchInput={searchInput}
                        onChoiceClick={onChoiceClick}
                        selectedResultsShow={selectedResultsShow}
                        pageCount={pageCount}
                        showPrevResults={showPrevResults}
                        showNextResults={showNextResults}
                        constants={constants}
                    />
                </div>
            </div>
            <div className="list-users__table-list-users">
                {/*<div className="list-users__mobile-search-input">*/}
                {/*    <input className="list-users__mobile-input" placeholder="Поиск по списку" />*/}
                {/*    <img className="list-users__mobile-icon" alt={orgSearchIconMobile} src={orgSearchIconMobile} />*/}
                {/*</div>*/}
                <div className="table-list-users-header">
                    <p className="table-list-users-header__e-mail">{constants.LIST_USERS.LIST_USERS_TABLE_EMAIL}
                        <img onClick={() => onChangeTypeSortEmail(isSortEmailToggle)} className="header-table-group-users__name-group-icon-sort" src={iconUpDownSort} alt={constants.GENERAL.ALT_ICON} /></p>
                    <p className="table-list-users-header__username">{constants.LIST_USERS.LIST_USERS_USERNAME}
                        <img onClick={() => onChangeTypeSortUsername(isSortLastNameToggle)} className="header-table-group-users__name-group-icon-sort" src={iconUpDownSort} alt={constants.GENERAL.ALT_ICON} /></p>
                    <p className="table-list-users-header__actions">{constants.LIST_USERS.LIST_USERS_ACTIONS}</p>
                </div>
                {usersForRender.length !== 0 ? (
                    <>
                    {usersForRender.slice(showResultsFrom, resultsShow).map((user) => (
                        <div key={user?.id} className="list-users-row">
                            <p onClick={() => handleSelectUser(user?.id)} className="list-users-row__e-mail">{user?.email}</p>
                            <p className="list-users-row__username">{user?.last_name} {user?.first_name} {user?.second_name}</p>
                            <div onClick={() => handleSelectUser(user?.id)} className="list-users-row-show-profile">
                                <img className="list-users-row-show-profile__icon" src={iconEye} alt={iconEye} />
                                <p className="list-users-row-show-profile__show">{constants.LIST_USERS.LIST_USERS_WATCH_PROFILE}</p>
                            </div>
                        </div>
                    ))}
                    </>
                ) : (
                    <div className="list-users-row__message-empty">
                        <p className="table-list-users__no-users">{constants.ADD_NEW_ORG.ADD_NEW_ORG_NO_USERS}<span className="name-org-input__red-star"> *</span></p>
                    </div>
                )}
                {/*<p className="list-users-row-show-more">{constants.LIST_USERS.LIST_USERS_SHOW_MORE}</p>*/}
            </div>
            <div className="list-users__sorting-pagination">
                <div className="list-users__pagination">
                    <PaginationBlock
                        sortList={usersForRender}
                        search={usersSearch}
                        searchInput={searchInput}
                        onChoiceClick={onChoiceClick}
                        selectedResultsShow={selectedResultsShow}
                        pageCount={pageCount}
                        showPrevResults={showPrevResults}
                        showNextResults={showNextResults}
                        constants={constants}
                    />
                </div>
            </div>
        </div>
    )
}
export default ListUsers;