import React, {useEffect, useState} from "react";
import * as Organizations from '../../Api/Organizations';
import { Validation } from '../../utils/Validation/Validation';
import {setCorrectNameProtocol} from "../../utils/SetCorrectNameProtocol/setCorrectNameProtocol";
import row_input_select_role from "../../img/Auth_icon_row_select_role.svg";
import {useOnClickOutsideModal} from "../../utils/CustomHooks/useOutsideModal/useOutsideModal";

const SubscriptionSettings = (props) => {

    const {
        constants,
        requestHelper,
        org,
        reloadOrgPage
    } = props;

    const votersLimit = Validation();
    const eventsLimit = Validation();
    const domainName = Validation();
    const messageLimit = Validation();
    const [subscriptionFrom, setSubscriptionFrom] = useState('');
    const [subscriptionTo, setSubscriptionTo] = useState('');
    const [isMessageCheckboxActive, setMessageCheckboxActive] = useState(false);
    const [isActiveSaveButton, setActiveSaveButton] = useState(false);
    const [isActiveSelectListOption, setActiveSelectListOption] = useState(false);
    const [isSelectedOption, setSelectedOption] = useState('');
    useOnClickOutsideModal(isActiveSelectListOption, () => setActiveSelectListOption(false));

    const isOptionSelectList = [
        {
            value: 'Опция1'
        },
        {
            value: 'Опция2'
        },
        {
            value: 'Опция3'
        }
    ]

    function subscriptionFromChange(evt) {
        setSubscriptionFrom(evt.target.value);
    }

    function subscriptionToChange(evt) {
        setSubscriptionTo(evt.target.value);
    }

    function handleMessageCheckboxActive() {
        if (isMessageCheckboxActive) {
            setMessageCheckboxActive(false);
        } else {
            setMessageCheckboxActive(true);
        }
    }

    // Раскомментировать метод, если нужно прибавлять к дате 1 день для отправки на API.

    // function datePlus (date) {
    //     const defaultDate = new Date(date);
    //     const datePlus = defaultDate.setDate(defaultDate.getDate() + 1);
    //     const newDate = new Date(datePlus);
    //     const dateForSend = `${newDate.getFullYear() + '.' + (newDate.getMonth() + 1) + '.' + newDate.getDate()}`;
    //     return dateForSend;
    // }

    useEffect(() => {
        let isMounted = true;
        if (org?.config?.orgPermissions !== undefined && org?.config?.orgPermissions.status === 'ACTIVE') {
            isMounted && votersLimit.setValue(org?.config?.orgPermissions?.votersLimit);
            isMounted && eventsLimit.setValue(org?.config?.orgPermissions?.eventsLimit);
        }
        return () => {
            isMounted = false;
        }
    },[org?.config?.orgPermissions?.votersLimit, org?.config?.orgPermissions?.eventsLimit])

    useEffect(() => {
        let isMounted = true;
        if(votersLimit.value && eventsLimit.value && subscriptionFrom && subscriptionTo !== '') {
            isMounted && setActiveSaveButton(true);
        } else {
            isMounted && setActiveSaveButton(false);
        }
        return () => {
            isMounted = false;
        }
    },[votersLimit.value, eventsLimit.value, subscriptionFrom, subscriptionTo])

    function saveChanges() {
        const startTime = subscriptionFrom !== '' ? subscriptionFrom.split('-').join('.') : null;
        const endTime = subscriptionTo !== '' ? subscriptionTo.split('-').join('.') : null;

        // если нужно прибавить к дате 1 день, использовать startDate и endDate вместо startTime и endTime для отправки на API
        // так как API почему-то возвращает дату за минусом 1 день. Уточнить почему...

        // const startDate = datePlus(startTime);
        // const endDate = datePlus(endTime);
        // console.log(startDate);
        // console.log(endDate);

        const body = {
            permission: {
                eventsLimit: eventsLimit.value,
                votersLimit: votersLimit.value,
                startTime: startTime,
                endTime: endTime,
                // зачем нужно отправлять статус?
                status: "ACTIVE"
            },
            org_id: org.id
        };
        requestHelper(Organizations.addSubscription, body)
            .then((res) => {
                reloadOrgPage();
            })
            .catch((err) => {
                throw new Error(err.message);
            })
        setActiveSaveButton(false)
    }

    const onHandleSelectOption = (value) => {
        setSelectedOption(value)
    }

    return (
        <div className="subscription-settings">
            <div className="subscription-settings-header">
                <h2 className="subscription-settings__heading">{constants.ORG_SETTINGS.SUBSCRIPTION_SETTINGS}</h2>
                <div className="subscription-settings-header__select-option">
                    <div onClick={() => setActiveSelectListOption(!isActiveSelectListOption)} className="add-new-vote__time-zone-select-container">
                        <p className="add-new-vote__time-zone-select-value">{isSelectedOption === '' ? 'Выбрать опции' : isSelectedOption}</p>
                        <img className="add-new-vote__time-zone-select-arrow" src={row_input_select_role} alt="Стрелочка открытия меню" />
                        <div className={isActiveSelectListOption ? "add-new-vote__time-zone-options-container" : "add-new-vote__time-zone-options-container hidden"}>
                            {isOptionSelectList.map((el, i) => {
                                return (
                                    <div key={i} className="add-new-vote__time-zone-option-container" onClick={() => onHandleSelectOption(el.value)}>
                                        <p className="add-new-vote__time-zone-option">{el.value}</p>
                                    </div>
                                )}
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="subscription-settings__date-main-continer">
                <div className="subscription-settings__date-continer">
                    <p className="subscription-settings__date-text">{constants.ORG_SETTINGS.DATE_START}</p>
                    <input
                        type="date"
                        className="subscription-settings__date-input"
                        onChange={subscriptionFromChange}
                        value={subscriptionFrom}
                    />
                </div>
                <div className="subscription-settings__date-continer">
                    <p className="subscription-settings__date-text">{constants.ORG_SETTINGS.DATE_END}</p>
                    <input
                        type='date'
                        className="subscription-settings__date-input"
                        onChange={subscriptionToChange}
                        value={subscriptionTo}
                    />
                </div>
            </div>
            <div className="subscription-settings__permissions-main-container">
                <div className="subscription-settings__permission-container">
                    <p className="subscription-settings__permission-heading">{constants.ORG_SETTINGS.VOTERS_LIMIT}</p>
                    <input
                        type="number"
                        className="subscription-settings__permission-input"
                        name="voters-limit"
                        value={votersLimit.value}
                        onChange={votersLimit.onChange}
                    />
                </div>
                <div className="subscription-settings__permission-container">
                    <p className="subscription-settings__permission-heading">{constants.ORG_SETTINGS.EVENTS_LIMIT}</p>
                    <input
                        type="number"
                        className="subscription-settings__permission-input"
                        name="events-limit"
                        value={eventsLimit.value}
                        onChange={eventsLimit.onChange}
                    />
                </div>
            </div>
            <div className="subscription-settings__permissions-main-container">
                <div className="subscription-settings__permission-container">
                    <p className="subscription-settings__permission-heading">{constants.ORG_SETTINGS.ADD_DOMAIN}</p>
                    <input
                        type="number"
                        className="subscription-settings__permission-input"
                        name="voters-limit"
                        value={domainName.value}
                        onChange={domainName.onChange}
                    />
                </div>
            </div>
            <div className="subscription-settings__message-container">
                <div className="subscription-settings__message-checkbox-container" onClick={handleMessageCheckboxActive}>
                    <div className={`subscription-settings__message-checkbox ${isMessageCheckboxActive && 'subscription-settings__message-checkbox_active'}`} />
                    <p className="subscription-settings__message-checkbox-text">{constants.ORG_SETTINGS.MESSAGE_CHECKBOX}</p>
                </div>
                {isMessageCheckboxActive && (
                    <div className="subscription-settings__message-input-container">
                        <p className="subscription-settings__message-input-heading">{constants.ORG_SETTINGS.MESSAGE_INPUT}</p>
                        <input
                            type="number"
                            className="subscription-settings__message-input"
                            name="message-limit"
                            value={messageLimit.value}
                            onChange={messageLimit.onChange}
                        />
                    </div>
                )}
            </div>
            {isActiveSaveButton &&
                <button className="subscription-settings__save-changes-button" onClick={saveChanges}>{constants.ORG_SETTINGS.BUTTON_SAVE_ADMINS_CHANGE}</button>
            }
        </div>
    )
}
export default SubscriptionSettings;
