import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { config } from '../../config';
import * as en from '../../utils/Localization/En/constants';
import * as ru from '../../utils/Localization/Ru/constants';
import Auth from "../Auth/Auth";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import OrganizationsList from "../OrganizationsList/OrganizationsList";
import AddNewOrganization from "../AddNewOrganization/AddNewOrganization";
import ProfileUser from "../ProfileUser/ProfileUser";
import timeZone from '../../utils/TimeZoneData/TimeZoneRu.json';
import * as UserAuth from '../../Api/Auth';
import * as Event from "../../Api/Events";
import * as Users from "../../Api/Users";
import * as Template from "../../Api/Template";
import GroupUsers from "../GroupUsers/GroupUsers";
import ListUsers from "../ListUsers/ListUsers";
import AddNewGroupUsers from "../AddNewGroupUsers/AddNewGroupUsers";
import Main from "../Main/Main";
import GroupUsersSelectNameGroup from "../GroupUsersSelectNameGroup/GroupUsersSelectNameGroup";
import VotesPage from "../VotesPage/VotesPage";
import AddNewVote from "../AddNewVote/AddNewVote";
import OrgSettings from "../OrgSettings/OrgSettings";
import ListTemplates from "../ListTemplates/ListTemplates";
import DetailsVote from "../DetailsVote/DetailsVote";
import useLocalstorage from "../../utils/CustomHooks/useLocalstorage/useLocalstorage";
import PreLoaderBlock from "../PreLoaderBlock/PreLoaderBlock";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import {NoMatchRoutes} from "../../utils/NoMatchRoutes/NoMatchRoutes";
import {appRequestsAuthUser} from "../../utils/AppAllRequestsForRoleUser/appAllRequestsForRoleUser";
import {appGetCommonStatistic} from "../../utils/AppGetCommonStatistic/appGetCommonStatistic";
import ListModerators from "../ListModerators/ListModerators";

function App() {
    const navigate = useNavigate();
    const { currentUser, setCurrentUser, stateReducer, runUpdateDataEvents, resetStateAddEvents } = useCurrentUserContext();
    const [constants, setConstants] = useState(ru.constants);
    const [changeLanguageBtn, setChangeLanguageBtn] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isRememberMe, setRememberMe] = useState(true);
    const [allEvents, setAllEvents] = useLocalstorage([], 'eventList');
    const [allGroups, setAllGroups] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allModerators, setAllModerators] = useState([]);
    const [allTemplates, setAllTemplates] = useState([]);
    const [isCommonStatistic, setCommonStatistic] = useState([]);
    const [userName, setUserName] = useState('');
    const [isAuthFormValid, setAuthFormValid] = useState(true);
    const [authErrorMessage, setAuthErrorMessage] = useState('');
    const [isUserProfileErrorMessage, setUserProfileErrorMessage] = useState('');
    const [isPreloaderAuthBtn, setPreloaderAuthBtn] = useState(false);
    const [authAs, setAuthAs] = useState('');
    const [isSelectedTemplate, setSelectedTemplate] = useState({});
    const [utcOffset, setUtcOffset] = useLocalstorage('', 'utcOffset');
    const [changeUtcOffset, setChangeUtcOffset] = useLocalstorage('', 'changeUtcOffset');
    const { pathname } = useLocation();
    const [isPreloaderActive, setPreloaderActive] = useState(false);
    const [isUserProfile, setUserProfile] = useLocalstorage({}, 'selectedUser');

    function requestHelper(request, body = {}) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('jwt')) {
                const jwt = localStorage.getItem('jwt');
                const jwtTokens = JSON.parse(jwt);
                request(jwtTokens.access_token, body)
                    .then((res) => {
                        if (res.status === 'failure') {
                            UserAuth.getNewTokens(jwtTokens.refresh_token)
                                .then((newTokens) => {
                                    if (newTokens.status === 'failure') {
                                        logout();
                                    } else {
                                        localStorage.setItem('jwt', JSON.stringify(newTokens));
                                        request(newTokens.access_token, body)
                                            .then((res) => {
                                                resolve(res);
                                            })
                                            .catch((err) => {
                                                throw new Error(err.message);
                                            })
                                    }
                                })
                                .catch((err) => {
                                    throw new Error(err.message);
                                })
                        } else {
                            resolve(res);
                        }
                    })
                    .catch((err) => {
                        disactivatePreloader()
                        throw new Error(err.message);
                    })
            } else {
                logout();
            }
        })
    }

    function handleLangChange(value) {
        const lang = {
            lang: value
        }
        if (value === 'ru') {
            setConstants(ru.constants);
            localStorage.setItem('lang', JSON.stringify(lang));
            setChangeLanguageBtn(true);
        } else {
            setConstants(en.constants);
            localStorage.setItem('lang', JSON.stringify(lang));
            setChangeLanguageBtn(false);
        }
    }

    function setOffset(utc) {
        if (utc !== undefined) {
            const localOffset = timeZone.find(value => value.VALUE === utc.toString())
            setUtcOffset(localOffset.LABEL);
            setChangeUtcOffset(localOffset.VALUE);
        } else {
            console.log('')
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (localStorage.getItem('lang')) {
            const storageLang = localStorage.getItem('lang');
            const lang = JSON.parse(storageLang);
            if (lang.lang === 'en') {
                isMounted && setConstants(en.constants);
                isMounted && setChangeLanguageBtn(false);
            } else {
                isMounted && setConstants(ru.constants);
                isMounted && setChangeLanguageBtn(true);
            }
        } else {
            isMounted && setConstants(ru.constants);
        }
        return () => {
            isMounted = false;
        }
    }, []);

    function handleRememberMe() {
        if (isRememberMe) {
            setRememberMe(false);
        } else {
            setRememberMe(true);
        }
    }

    function activatePreloader() {
        setPreloaderActive(true);
    }

    function disactivatePreloader() {
        setPreloaderActive(false);
    }

    function addCurrentUser(user) {
        setCurrentUser(user);
    }

    const userDefaultName = {
        lastName: "Неизвестный",
        firstName: "Пользователь"
    }

    function createUserName(user) {
        const firstName = function () {
            if (user.first_name === "" || user.first_name === undefined) {
                return `${userDefaultName.firstName.charAt(0)}`;
            } else {
                return `${user.first_name.charAt(0)}`;
            }
        }
        const lastName = function () {
            if (user.last_name === "" || user.last_name === undefined) {
                return userDefaultName.lastName
            } else {
                return user.last_name;
            }
        }
        const middleName = function () {
            if (user.second_name === "" || user.second_name === undefined) {
                return ""
            } else {
                return `${user.second_name.charAt(0)}.`;
            }
        };
        const shortName = `${lastName()} ${firstName()}.${middleName()}`;
        setUserName(shortName);
    }

    function handleAuthError(isValid) {
        setAuthFormValid(isValid);
    }

    function handleAuthErrorMessage(message) {
        setAuthErrorMessage(message);
    }

    function logout() {
        if (localStorage.getItem('user')) {
            localStorage.removeItem('user');
        }
        if (localStorage.getItem('jwt')) {
            localStorage.removeItem('jwt');
        }
        if (localStorage.getItem('eventList')) {
            localStorage.removeItem('eventList');
        }
        if (localStorage.getItem('currentEvent')) {
            localStorage.removeItem('currentEvent');
        }
        if (localStorage.getItem('currentOrgId', )) {
            localStorage.removeItem('currentOrgId');
        }
        if (localStorage.getItem('currentGroupId')) {
            localStorage.removeItem('currentGroupId');
        }
        if (localStorage.getItem('utcOffset')) {
            localStorage.removeItem('utcOffset')
        }
        if (localStorage.getItem('changeUtcOffset')) {
            localStorage.removeItem('changeUtcOffset')
        }
        if (localStorage.getItem('selectedUser')) {
            localStorage.removeItem('selectedUser')
        }
        setLoggedIn(false);
        setCurrentUser({});
        setUserName('');
        navigate('/auth');
        disactivatePreloader();
    }

    function handleCurrentEvents(data) {
        const currentEvent = {
            id: data.id
        }
        if (localStorage.getItem('currentEvent')) {
            localStorage.removeItem('currentEvent');
            localStorage.setItem('currentEvent', JSON.stringify(currentEvent));
            navigate('/details-vote');
        } else {
            localStorage.setItem('currentEvent', JSON.stringify(currentEvent));
            navigate('/details-vote');
        }
    }

    function handleSelectUser(userId) {
        requestHelper(Users.getUser, userId)
            .then((data) => {
                setUserProfile(data);
                navigate('/profile-user')
            })
            .catch((err) => {
                throw new Error(err.message);
            })
        window.scrollTo(0, 0);
    }

    function handleResetIsSelectedTemplateMode(nameButton, setActive) {
        switch (nameButton) {
            case "addVote" :
                setSelectedTemplate({});
                navigate('/add-new-vote');
                resetStateAddEvents('addVote');
                break;
            case "addTemplate" :
                setSelectedTemplate({});
                navigate('/add-new-template');
                resetStateAddEvents('addTemplate');
                break;
            case "linkAddNewVote" :
                setSelectedTemplate({});
                navigate('/add-new-vote');
                break;
            default: return
        }
        if (setActive !== undefined) {
            setActive(false)
        }
    }

    function handleSelectTemplate(template) {
        requestHelper(Template.getTemplate, template)
            .then((data) => {
                setSelectedTemplate(data);
                navigate('/add-new-vote')
            })
            .catch((err) => {
                throw new Error(err.message);
            })
    }

    function handleChangeDataUser(profileUser, bodyChangeDataUser) {
        const nameRegExp = /^([а-яё\s-]+|[a-z\s-]+)$/i;
        const passRegExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*,.:;+<>{}?\\[\]/_-]{8,64}$/;
        const {repeatPassword, ...modifyBodyRequest} = bodyChangeDataUser
        if (nameRegExp.test(bodyChangeDataUser.last_name) === false || nameRegExp.test(bodyChangeDataUser.first_name) === false || nameRegExp.test(bodyChangeDataUser.second_name) === false) {
            setUserProfileErrorMessage(`${constants.PROFILE_USER.PROFILE_USER_CHANGE_DATA_ERROR_WRONG_FORMAT}`)
            return;
        } else if(bodyChangeDataUser.password !== bodyChangeDataUser.repeatPassword) {
            setUserProfileErrorMessage(`${constants.PROFILE_USER.PROFILE_USER_CHANGE_DATA_ERROR_PASSWORD_MISMATCH}`)
            return;
        } else if(bodyChangeDataUser.password !== '' || bodyChangeDataUser.repeatPassword !== '') {
            if (passRegExp.test(bodyChangeDataUser.password) === false
                || passRegExp.test(bodyChangeDataUser.repeatPassword) === false) {
                setUserProfileErrorMessage(`${constants.PROFILE_USER.PROFILE_USER_CHANGE_DATA_ERROR_RULE_PASS}`)
                return;
            }
        }
        const body = {
            id: profileUser?.id,
            bodyRequest: modifyBodyRequest
        }
        if (profileUser?.id === currentUser?.id) {
            requestHelper(Users.updateUser, body)
                .then((data) => {
                    if (data.status === 'failure') {
                        console.log(data)
                    } else {
                        let userObjStorage = localStorage.getItem('user')
                        localStorage.removeItem('key')
                        let userObjStorageParse = JSON.parse(userObjStorage)
                        let updateUserObjStorageParse = {...userObjStorageParse, first_name: data.first_name, last_name: data.last_name, second_name: data.second_name}
                        localStorage.setItem('user', JSON.stringify(updateUserObjStorageParse))
                        createUserName(updateUserObjStorageParse)
                        setUserProfileErrorMessage('')
                        handleSelectUser(profileUser.id)
                        setOffset(data.utc_offset);
                    }
                })
                .catch((err) => {
                    throw new Error(err.message);
                })
        } else {
            requestHelper(Users.updateUser, body)
                .then((data) => {
                    if (data.status === 'failure') {
                        console.log(data)
                    } else {
                        setUserProfileErrorMessage('')
                        handleSelectUser(profileUser.id)
                    }
                })
                .catch((err) => {
                    throw new Error(err.message);
                })
        }
    }

    function handleDeleteEvents(data) {
        const body = [data.id]
        activatePreloader();
        requestHelper(Event.deleteEvent, body)
            .then((data) => {
                if (data) {
                    runUpdateDataEvents();
                    navigate('/list-votes');
                } else {
                    navigate('/');
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                disactivatePreloader();
            })
        }

    function handleLogin(email, password, authAs) {
        setPreloaderAuthBtn(true);
        UserAuth.authorize(email, password, authAs)
            .then((res) => {
                if (res.status === 'failure') {
                    handleAuthError(false);
                    handleAuthErrorMessage(constants.AUTH.AUTH_ERROR_MESSAGE);
                } else if (res.status === 'Permission denied') {
                    handleAuthError(false);
                    handleAuthErrorMessage(constants.AUTH.AUTH_ERROR_MESSAGE);
                } else {
                    if (isRememberMe) {
                        localStorage.setItem('user', JSON.stringify(res));
                    }
                    handleAuthError(true);
                    handleAuthErrorMessage('');
                    setLoggedIn(true);
                    addCurrentUser(res);
                    createUserName(res);
                    setOffset(res.utc_offset);
                    setAuthAs(res.authAs);
                    navigate('/');
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                setPreloaderAuthBtn(false);
            })
    }

     useEffect(() => {
        let isMounted = true;
         appRequestsAuthUser(currentUser, requestHelper, isMounted, setAllEvents, setAllGroups, setAllUsers, setAllTemplates, setAllModerators);
         appGetCommonStatistic(requestHelper, isMounted, setCommonStatistic);
         return () => {
             isMounted = false
         }
     },[currentUser.authAs, stateReducer.updateDataEvents])

    useEffect(() => {
        let isMounted = true;
        if (localStorage.getItem('user')) {
            const userData = localStorage.getItem('user');
            const user = JSON.parse(userData);
            isMounted && addCurrentUser(user);
            isMounted && createUserName(user);
            isMounted && setLoggedIn(true);
            isMounted && setAuthAs(user.authAs);
            if (!(
                pathname === '/' ||
                pathname === '/add-org-page' ||
                pathname === '/organizations' ||
                pathname === '/profile-user' ||
                pathname === '/org-settings' ||
                pathname === '/add-new-group' ||
                pathname === '/add-new-vote' ||
                pathname === '/group-users' ||
                pathname === '/group-users/selected-name-group' ||
                pathname === '/list-users' ||
                pathname === '/list-votes' ||
                pathname === '/list-templates' ||
                pathname === '/add-new-template' ||
                pathname === '/list-moderators'
            )) {
                navigate('/');
            }
        } else {
            if (!(
                pathname === '/auth'
            )) {
                logout();
            }
        }
        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line
    }, []);

    function onOrgSettingsClick(org) {
        const currentOrg = {
            id: org.id
        }
        localStorage.setItem('currentOrgId', JSON.stringify(currentOrg));
        navigate('/org-settings');
    }

    function onHandleGroupClick(groupId) {
        localStorage.setItem('currentGroupId', JSON.stringify(groupId));
        navigate('/group-users/selected-name-group');
    }

    function formatDate(serverDate) {
        const localDate = new Date(serverDate.toString());
        const defaultDate = localDate.getDate();
        const date = `${defaultDate.toString().length === 1 ? `${'0' + defaultDate}` : `${defaultDate}`}`;
        const defaultMonth = localDate.getMonth() + 1;
        const month = `${defaultMonth.toString().length === 1 ? `${'0' + defaultMonth}` : `${defaultMonth}`}`;
        const year = localDate.getFullYear();
        return `${date + '.' + month + '.' + year}`;
    }

    function formatTime(serverDate) {
        const localDate = new Date(serverDate)
        const currentDate = new Date();
        const getUtsCurrent = currentDate.getTimezoneOffset();
        const localDateUtc = localDate.getTimezoneOffset();
        if (localDateUtc !== Number(changeUtcOffset * (-60))) {
            const serverOffsetMillis = 60 * 1000 * Number(changeUtcOffset * -60);
            const sumGetUtccurrent = 60 * 1000 * getUtsCurrent;
            const localOffset = new Date(localDate.getTime() - serverOffsetMillis + sumGetUtccurrent);
            const defaultHours = localOffset.getHours();
            const hoursChangeUtc = `${defaultHours.toString().length === 1 ? `${'0' + defaultHours}` : `${defaultHours}`}`;
            const defaultMinutes = localDate.getMinutes();
            const minutes = `${defaultMinutes.toString().length === 1 ? `${'0' + defaultMinutes}` : `${defaultMinutes}`}`;
            return `${hoursChangeUtc + ':' + minutes}`;
        }
        else {
            const defaultHours = localDate.getHours();
            const hours = `${defaultHours.toString().length === 1 ? `${'0' + defaultHours}` : `${defaultHours}`}`;
            const defaultMinutes = localDate.getMinutes();
            const minutes = `${defaultMinutes.toString().length === 1 ? `${'0' + defaultMinutes}` : `${defaultMinutes}`}`;
            return `${hours + ':' + minutes}`;
        }
    }

    return (
            <div className="app">
                {isPreloaderActive && (
                    <PreLoaderBlock />
                )}
                {isLoggedIn && (
                    <Header
                        constants={constants}
                        handleLogout={logout}
                        handleSelectUser={handleSelectUser}
                        handleResetIsSelectedTemplateMode={handleResetIsSelectedTemplateMode}
                        userName={userName}
                        authAs={authAs}
                    />
                )}
                <Routes>
                    <Route path={'/auth'}
                        element={<Auth
                            handleLangChange={handleLangChange}
                            constants={constants}
                            changeLanguageBtn={changeLanguageBtn}
                            handleRememberMe={handleRememberMe}
                            isRememberMe={isRememberMe}
                            config={config}
                            handleLogin={handleLogin}
                            isAuthFormValid={isAuthFormValid}
                            handleAuthError={handleAuthError}
                            handleAuthErrorMessage={handleAuthErrorMessage}
                            authErrorMessage={authErrorMessage}
                            isPreloaderAuthBtn={isPreloaderAuthBtn}
                        />}
                    />
                    <Route exact path={'/'}
                        element={<Main
                            isCommonStatistic={isCommonStatistic}
                            constants={constants}
                        />}
                    />
                    <Route exact path={'/organizations'}
                        element={<OrganizationsList
                            constants={constants}
                            requestHelper={requestHelper}
                            onOrgSettingsClick={onOrgSettingsClick}
                        />}
                    />
                    <Route path={'/org-settings'}
                        element={<OrgSettings
                            constants={constants}
                            requestHelper={requestHelper}
                            authAs={authAs}
                        />}
                    />
                    <Route path={authAs === 'superAdmin' ? '/add-org-page' : '/'}
                        element={<AddNewOrganization
                            constants={constants}
                            requestHelper={requestHelper}
                        />}
                    />
                    <Route path={authAs === 'admin' ? '/add-new-group' : '/'}
                        element={<AddNewGroupUsers
                            constants={constants}
                            requestHelper={requestHelper}
                        />}
                    />
                    <Route path={authAs === 'admin' ? '/add-new-vote' : '/'}
                        element={<AddNewVote
                            allUsers={allUsers}
                            allGroups={allGroups}
                            isSelectedTemplate={isSelectedTemplate}
                            constants={constants}
                            requestHelper={requestHelper}
                        />}
                    />
                    <Route path={authAs === 'admin' ? '/add-new-template' : '/'}
                           element={<AddNewVote
                               isSelectedTemplate={isSelectedTemplate}
                               constants={constants}
                               requestHelper={requestHelper}
                           />}
                    />
                    <Route path={'/profile-user'}
                        element={<ProfileUser
                            handleCurrentEvents={handleCurrentEvents}
                            onOrgSettingsClick={onOrgSettingsClick}
                            onHandleGroupClick={onHandleGroupClick}
                            handleChangeDataUser={handleChangeDataUser}
                            isUserProfileErrorMessage={isUserProfileErrorMessage}
                            isUserProfile={isUserProfile}
                            setUserProfileErrorMessage={setUserProfileErrorMessage}
                            constants={constants}
                        />}
                    />
                    <Route exact path={'/group-users'}
                        element={<GroupUsers
                            constants={constants}
                            authAs={authAs}
                            allGroups={allGroups}
                            requestHelper={requestHelper}
                            onHandleGroupClick={onHandleGroupClick}
                        />}
                    />
                    <Route path={'/group-users/selected-name-group'}
                        element={<GroupUsersSelectNameGroup
                            constants={constants}
                            authAs={authAs}
                            requestHelper={requestHelper}
                        />}
                    />
                    <Route path={'/list-users'}
                        element={<ListUsers
                            allUsers={allUsers}
                            handleSelectUser={handleSelectUser}
                            constants={constants}
                        />}
                    />
                    <Route path={authAs === 'superAdmin' ? '/list-moderators' : '/'}
                        element={<ListModerators
                            requestHelper={requestHelper}
                            allModerators={allModerators}
                            constants={constants}
                        />}
                    />
                    <Route exact path={'/list-votes'}
                        element={<VotesPage
                            constants={constants}
                            authAs={authAs}
                            allEvents={allEvents}
                            handleCurrentEvents={handleCurrentEvents}
                            handleDeleteEvents={handleDeleteEvents}
                            formatDate={formatDate}
                            formatTime={formatTime}
                            utcOffset={utcOffset}
                        />}
                    />
                    <Route path={'/details-vote'}
                           element={<DetailsVote
                               constants={constants}
                               authAs={authAs}
                               requestHelper={requestHelper}
                               activatePreloader={activatePreloader}
                               handleResetIsSelectedTemplateMode={handleResetIsSelectedTemplateMode}
                               disactivatePreloader={disactivatePreloader}
                               formatDate={formatDate}
                               formatTime={formatTime}
                           />}
                    />
                    <Route exact path={'/list-templates'}
                           element={<ListTemplates
                               constants={constants}
                               allTemplates={allTemplates}
                               handleSelectTemplate={handleSelectTemplate}
                               requestHelper={requestHelper}
                               formatDate={formatDate}
                               formatTime={formatTime}
                           />}
                    />
                    <Route path={'*'}
                           element={<NoMatchRoutes
                               constants={constants}
                               isLoggedIn={isLoggedIn}
                           />}
                    />
                </Routes>
                {isLoggedIn && (
                    <Footer
                        handleSelectUser={handleSelectUser}
                        handleLangChange={handleLangChange}
                        constants={constants}
                        changeLanguageBtn={changeLanguageBtn}
                        authAs={authAs}
                    />
                )}
            </div>
    )
}
export default App;
